import authService from '../../auth/authService'

const userDefaults = {
  uid: 0,
  displayName: '',
  about: '',
  photoURL: require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  status: 'online',
  userRole: '/'
}

export default {
  namespaced: true,
  state: {
    AppActiveUser: userDefaults,
    loggedIn: false,
    userData: null
  },
  getters: {
    userData: state => {
      return state.userData
    },
    loggedIn: state => {
      return state.loggedIn
    }
  },
  mutations: {
    SET_LOGGED_IN(state) {
      state.loggedIn = true
    },
    UPDATE_USER_DATA(state, data) {
      state.userData = data
    },
    SET_LOGIN_STATUS(state, status) {
      state.loggedIn = status
    },
    async SET_USER_NO_CLIENTS(state, status) {
      const router = (await import('@/router')).default

      if (!status && router.currentRoute.path !== '/no-clients') {
        await router.push('no-clients')
      } else if (status && router.currentRoute.path === '/no-clients') {
        await router.push('/')
      }

      state.userHasClients = status
    }
  },
  actions: {
    login({ commit }, payload) {
      authService.setLoginParameters(payload)
      commit('SET_LOGGED_IN')
    },
    setUserData({ commit }, payload) {
      commit('UPDATE_USER_DATA', payload)
      localStorage.setItem('userData', JSON.stringify(payload))

      window.location = '/'
    },
    setLoginStatus({commit}, data) {
      commit('SET_LOGIN_STATUS', data)
    },
    updateUserData({commit}, data) {
      commit('UPDATE_USER_DATA', data)
    },
    setUserNoClients({commit}, data) {
      commit('SET_USER_NO_CLIENTS', data)
    }
  }
}
